import React from 'react';
import resportEvents from './reportEvents';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    // 调用父类的构造函数 —— 必须
    // ——继承父类的属性和方法
    // this.props 初始化，操作和处理传递给组件的属性。
    this.state = { hasError: false };
    // 初始化了组件的状态对象 state
  }

  componentDidCatch(error, info) {
    // 组件发生 JavaScript 错误时被调用。
    resportEvents(`Error: ${error.toString()}`, new Date(), info.componentStack);
    this.setState({ hasError: true });
  }

  render() {
    // 渲染错误页面的 UI
    if (this.state.hasError) {
      // 自定义错误处理 UI
      return <h1>前端组件报错了....设计考虑不周我十分抱歉(伏地</h1>;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
