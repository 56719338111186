// config.js

const config = {
    translate: 'https://xiaoshenbiubiubiu.space/translate',
    loginVPN:'https://xiaoshenbiubiubiu.space/user/loginVPN',
    loginAll:'https://xiaoshenbiubiubiu.space/user/login',
    foodRequest:'https://xiaoshenbiubiubiu.space/foodRequest',
    convert :'https://xiaoshenbiubiubiu.space/convert',
    report:'https://xiaoshenbiubiubiu.space/report',
    MusicUrlGet:'https://xiaoshenbiubiubiu.space/MusicUrlGet'
  };
  
  export default config;
  