import { Link, BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react'
import './App.css';
// 滚动展示
// import { Carousel } from 'antd';
import Login from './Login';
import { useNavigate } from "react-router-dom";
// import authService from './auth'; // Import the authentication service
import resportEvents from './reportEvents';

// 翻墙页
function Overwall() {
    const [isLoggedIn, setLoggedIn] = useState(false);
    const navigate = useNavigate();
    const handleBack = () => {
      resportEvents('back')
      navigate(-1); // 后退到上一个历史记录
    };
    const handleLoginSuccess = () => {
      // window.location.reload();
      // 变更状态后，React 会自动触发组件的重新渲染
      // 不需要显式地调用渲染函数
      resportEvents('VPN页面登陆成功')
      setLoggedIn(true)
    };
    const handleLogout = () => {
      // authService.logout();
      setLoggedIn(false);
      // window.location.reload();
    };
    useEffect(() => {
    resportEvents('Overwall');
    const timer = setTimeout(() => {
    // 当组件渲染时（即 useEffect 第一次执行时），创建了一个定时器 timer。
      setLoggedIn(false);
    }, 30000);
    return () => clearTimeout(timer); // Clear the timer when the component unmounts
    // 如果 isLoggedIn 改变，useEffect 会执行清除定时器的操作。这确保了当组件卸载时，不会有未完成的定时器操作。
  }, [isLoggedIn]);
    return (
      <div className="Overwall">
        <button className='back-button' onClick={handleBack}>←</button>
        <div className='notice-board'>
        <div style={{overflow: 'hidden'}}>
          <div className="marquee">
            这个页面暂时先不开～&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This page is temporarily closed~&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;このページは一時的に閉鎖されています~&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;此頁暫唔開~
          </div>
        </div>
        </div>
        {isLoggedIn ? (
          <div className="login-success">
            <p className='vpn_content'>Login Success</p>
            <button onClick={handleLogout}>Logout</button>
          </div>
        ) : (
          <div>
            <Login onLoginSuccess={handleLoginSuccess} />
          </div>
        )}
      </div>
    );
  }

  export default Overwall;