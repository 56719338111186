import { Link, BrowserRouter as Router, Routes, Route, useLocation,useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react'
import './App.css';
import resportEvents from './reportEvents';
// 滚动展示
// import { Carousel } from 'antd';
// import Login from './Login';
import config from './config';
// import authService from './auth'; // Import the authentication service
function LoginAll({onLoginSuccess}){
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false); // 添加 isLoading 状态
  const navigate = useNavigate();
  const {loginAll} = config
  const handleBack = () => {
    navigate('/'); // 后退到上一个历史记录
  };
  const handleLoginClick = async () => {
    
    if (!username || !password) {
      alert('Input your username and password ,My lord');
      return;
  }
  setIsLoading(true); // 
    try {
      // const response = await fetch("http://localhost:8080/user/login", {
      const response = await fetch(loginAll, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: 'include',  // Include cookies in a cross-origin request
        body: JSON.stringify({
          username: username,
          password: password,
        }), 
      });
      console.log(response);
      if (response.ok) {
        const data = await response.json(); // 将返回的promise对象 解析响应数据为 JSON 格式
        const { username, token } = data; // 从响应数据中解构出用户名和令牌
  
        //对用户名和令牌进行保存或者处理，存储到 localStorage 中
        localStorage.setItem('username', username);
        localStorage.setItem('accessToken', token);
  
        alert('Login successful ⛵️ 开启旅程！');
        // navigate('/liuren');
        onLoginSuccess(); // 触发登录成功事件
        navigate("/");
      } else {
        const data = await response.json(); 
        const {error} = data;
        alert('login error:'+ error + ';' + '{看下是否挂代理了....}');
        resportEvents('login error:'+ username)
      }
    } catch (error) {
      // console.error('Error:', error);
      alert('service GG：'+error);
      resportEvents(`Error in loginAll: ${error.toString()}`)
    } finally {
      setIsLoading(false); // 无论请求成功或失败，都将加载状态置为 false
    }
  };

    return (
        <div className='LoginAll'>
            <button className='back-button' onClick={handleBack}>←</button>
            <form>
              <p>Username：<input type="text" value={username} onChange={(e) => setUsername(e.target.value)} /></p>
              <p>Password：<input type="password" value={password} onChange={(e) => setPassword(e.target.value)} /></p>
              <button className='loginButton' type="button" onClick={handleLoginClick} disabled={isLoading}>
                {isLoading ? 'Loading...' : 'Login'}
              </button>
            </form>
        </div>
      );
  }
export default LoginAll;