import { toast } from 'react-toastify'; // 引入 toast
import 'react-toastify/dist/ReactToastify.css'; // 引入样式
import resportEvents from './reportEvents';
import React,{ useState,useRef,useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import config from './config';
function Nutrition({extendLogin}){
    const [data, setData] = useState(null);
    const [unqueired,setunQueired] = useState(true)
    const [loading, setLoading] = useState(false); // 添加一个新的状态来追踪加载状态
    // const [requestNumber, setRequestNumber] = useState(0); // 添加状态来追踪请求编号
    const requestNumber = useRef(0); // 使用 useRef 来存储请求编号
    const lastRequestTimeRef = useRef(0);
    const [inputValue, setInputValue] = useState('');
    const [isInputFocused, setInputFocused] = useState(false);
    const navigate = useNavigate();
    const {foodRequest} = config
    const handleBack = () => {
      resportEvents('back')
      navigate(-1); // 后退到上一个历史记录
    };
    const handleSubmit = async (event) => {
        extendLogin();
        event.preventDefault();
        const currentTime = Date.now();

        const query = event.target.elements.query.value;
        const content = query.trim(); // 获取输入框中的值并去除首尾空格
        if (!content) { // 检查输入是否为空
            alert('Pls input something you ready to eat');
            return;
        }
        resportEvents("search:" + content); // 调用 resportEvents 函数，并传递 "search:" + content 作为参数
        if (currentTime - lastRequestTimeRef.current < 1000) {
            alert('点慢点点慢点呜呜呜～');
            // toast.error('点慢点点慢点呜呜呜～'); // 使用 toast 提示
            return;
        }
        lastRequestTimeRef.current = currentTime;
        setLoading(true); // 在请求开始时设置加载状态为 true
        requestNumber.current += 1; // 在请求开始时更新请求编号
        const currentRequestNumber = requestNumber.current;
        try {
            // const currentRequestNumber = requestNumber + 1;
            // setRequestNumber(currentRequestNumber); // 在请求开始时更新请求编号
            const response = await fetch(foodRequest, {
            // const response = await fetch('http://127.0.0.1:5000/foodRequest', {
                method: 'POST',
                body: JSON.stringify({ query: query ,index:currentRequestNumber}),
                headers: {
                    'Accept-Encoding': 'gzip', // 告知服务器接受gzip压缩的数据
                    'Content-Type': 'application/json'
                }
            });
            const data = await response.json();
            // console.log('-_____________')
            // console.log(data)
            // console.log('-_____________')
            setLoading(true); // 在请求开始时设置加载状态为 true
            // console.log(data['status'])
            // console.log(data.description)
            if (data['index'] === requestNumber.current) { // 只有当返回的结果对应的请求编号与当前的请求编号相同时，才更新数据
                // console.log('222212312321:'+currentRequestNumber)
                setData(data);
                setunQueired(false);
            }
        } catch (error) {
            // console.error('Error:', error);
            alert('service problem..。');
            resportEvents(`Error in Getting nutrition: ${error.toString()}`)
        } finally {
            setLoading(false); // 在请求结束时（无论成功还是失败）设置加载状态为 false
        }
    };
    useEffect(() => {
        // const session = window.sessionStorage.getItem('JSESSIONID');
        // console.log('useEffect in Nutrition component called');
        // console.log('Current data state:', data); // Log the current state of the data variable
        // console.log('Current unqueired state:', unqueired); // Log the current state of the unqueired variable
        // console.log('Current loading state:', loading); // Log the current state of the loading variable
        // const session = window.sessionStorage.getItem('JSESSIONID');
        resportEvents('Nutrition');
      }, []);

    return (
        <div className="Nutrition">
    {/* <Link to="/" className="back-button">
        <p className="back">←</p>
      </Link> */}
        <button className='back-button' onClick={handleBack}>←</button>
            <h1 className='title'>Nutrition ingredient</h1>
            <p className='version'>v1.0</p>
            <div className="form-container">
                <form onSubmit={handleSubmit}>
                    <div className="input-group">
                        <input 
                            name="query" 
                            type="text" 
                            placeholder="Input sth to eat" 
                            maxLength="20"
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                            onFocus={() => setInputFocused(true)}
                            onBlur={() => setInputFocused(false)}
                        />
                        {isInputFocused && inputValue && (
                            <button 
                            type="button" 
                            className="clear-button"
                            onMouseDown={(e) => {
                                e.preventDefault(); // 阻止输入框失去焦点
                                setInputValue('');
                            }}
                            >
                            ⊗
                            </button>
                        )}
                        </div>
                        <div className="search-button">
                            <button type="submit">search</button>
                        </div>
                </form>
            </div>
            <div className="table-container">
            {loading ? (
                <p>Loading...</p> // 如果正在加载，显示加载信息
            ) :
            data && !unqueired && data['status']!==500 &&  data.description ? (
                <table>
                    <tbody>
                        <tr>
                            <td>Description:</td>
                            <td>{data.description}</td>
                        </tr>
                        <tr>
                            <td>Package Weight:</td>
                            <td>{data.packageWeight}</td>
                        </tr>
                        {data.foodNutrients.map((nutrient, idx) => (
                            <tr key={idx}>
                                <td>{nutrient.nutrientName}:</td>
                                <td>{nutrient.value} {nutrient.unitName}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>{unqueired ? '' : 'No result～'}</p> // 根据 queired 的状态显示不同的提示信息
            )}
            </div>
        </div>
    );
}
export default Nutrition;