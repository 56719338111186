import { Outlet,Link, BrowserRouter as Router, Routes, Route, useLocation,useNavigate,Navigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react'
import './App.css';
import Music from './Music';
import Overwall from "./Overwall"
import Translation from './Translation';
import Nutrition from './Nutrition';
import Cartoons from './Cartoons';
import Tools from './Tools';
import Liuren from './Liuren';
import IniP from './Inip';
import LoginAll from './LoginAll';
import ErrorBoundary from './ErrorBoundary';
// subtitle setting
function PageTitleUpdater() {
  const location = useLocation();

  useEffect(() => {
    
    switch (location.pathname) {
      case '/':
        document.title = '首页捏捏～';
        break;
      case '/test':
        document.title = '测试捏捏～';
        break;
      case '/liuren/':
      case '/liuren':
        document.title = '小六壬';
        break;
      case '/overthewall/':
      case '/overthewall':
        document.title = "VPN";
        break;
      case '/translate/':
      case '/translate':
        document.title = "小小翻译官";
        break;
      case '/player/':
      case '/player':
        document.title = "我滴歌耶";
        break;
      case '/tools/':
      case '/tools':
        document.title = "常用工具";
        break;
      case '/nutrition':
      case 'nutrition/':
        document.title = "好好吃饭";
        break
      case '/cartoons':
      case '/cartoons/':
        document.title = "动漫一生追";
        break
      case '/login':
      case '/login/':
        document.title = '登陆耶✌️'
        break
      default:
        document.title = '进入了异世界！？';
    }
  }, [location.pathname]);
  return null; // This component doesn't render anything
}
function App() {
  // 总登陆态控制
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  // 全局定时器
  

  const handleLoginSuccess = () => {
    setIsLoggedIn(true);
  };

  // 阻止用户右滑返回
  // useEffect(() => {
  //   let touchStartX = 0;
  //   const handleTouchStart = (event) => {
  //     touchStartX = event.touches[0].clientX;
  //   };

  //   const handleTouchMove = (event) => {
  //     if (event.touches[0].clientX > touchStartX) {
  //       // 用户正在向右滑动
  //       event.preventDefault();
  //     }
  //   };
  //   window.addEventListener('touchstart', handleTouchStart);
  //   window.addEventListener('touchmove', handleTouchMove);
  //   return () => {
  //     window.removeEventListener('touchstart', handleTouchStart);
  //     window.removeEventListener('touchmove', handleTouchMove);
  //   };
  // }, []);

  // 定时清除登陆态——重回登陆页
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //   // 当组件渲染时（即 useEffect 第一次执行时），创建了一个定时器 timer。
  //   setIsLoggedIn(false);
  //   }, 5000);
  //   return () => clearTimeout(timer); // Clear the timer when the component unmounts
  //   // 如果 isLoggedIn 改变，useEffect 会执行清除定时器的操作。这确保了当组件卸载时，不会有未完成的定时器操作。
  // }, [isLoggedIn]);
  
  let timer;
const extendLogin = () => {
    // let timer;
    clearTimeout(timer);
    timer = setTimeout(() => {
      setIsLoggedIn(false);
      localStorage.removeItem('username');
      localStorage.removeItem('accessToken');
    }, 1800000);
  };

  // 用户有操作时不自动延长登陆态
  useEffect(() => {

    // // todo：存在“未登陆用户仍上报历史用户信息”bug，问题待解暂不上报
    // resportEvents('Init_Page')

    // let timer;
    clearTimeout(timer);//未能覆盖，需清空已有timer，使得后面timer能生效
    if (isLoggedIn) {
      timer = setTimeout(() => {
        setIsLoggedIn(false);
        localStorage.removeItem('username');
        localStorage.removeItem('accessToken');
      }, 1800000);
      // const extendLogin = () => {
      //   clearTimeout(timer);
      //   timer = setTimeout(() => {
      //     setIsLoggedIn(false);
      //   }, 10000);
      // };
      window.addEventListener('mousemove', extendLogin);
      window.addEventListener('keydown', extendLogin);
      return () => {
        clearTimeout(timer);
        window.removeEventListener('mousemove', extendLogin);
        window.removeEventListener('keydown', extendLogin);
      };
    }
  }, [isLoggedIn]);
  // // 左拦截v1
  // useEffect(() => {
  //   let touchStartX = 0;

  //   const handleTouchStart = (event) => {
  //     touchStartX = event.touches[0].clientX;
  //   };

  //   const handleTouchMove = (event) => {
  //     if (event.touches[0].clientX > touchStartX) {
  //       // 用户正在向右滑动
  //       event.preventDefault();
  //     }
  //   };

  //   window.addEventListener('touchstart', handleTouchStart);
  //   window.addEventListener('touchmove', handleTouchMove);

  //   return () => {
  //     window.removeEventListener('touchstart', handleTouchStart);
  //     window.removeEventListener('touchmove', handleTouchMove);
  //   };
  // }, []);


  // 左滑拦截v2——popstate
  // 导致白页面，react-router-dom v6 中，你不能直接阻止浏览器的后退行为，因为这可能会导致一些不可预见的问题。
  // const navigate = useNavigate();
  // const location = useLocation();
  // useEffect(() => {
  //   // 监听 'popstate' 事件
  //   window.addEventListener('popstate', handlePopState);

  //   return () => {
  //     // 在组件卸载时移除事件监听
  //     window.removeEventListener('popstate', handlePopState);
  //   };
  // }, []);

  // const handlePopState = (event) => {
  //   // 阻止浏览器的默认行为
  //   event.preventDefault();
  //   // 将用户导航到当前页面
  //   // navigate(location.pathname);
  // };
  return (
    <ErrorBoundary>
    <Router>
      <PageTitleUpdater />
      <Routes>
        {/* <Route path="/liuren" element={<Liuren />} />
        <Route path="/" element={<IniP />} />
        <Route path="/overthewall" element={<Overwall />} />
        <Route path="/tools" element={<Tools / >} />
        <Route path="/player" element={<Music  extendLogin={extendLogin}/>} />
        <Route path="/translate" element={<Translation / >} />
        <Route path="/nutrition" element={<Nutrition / >} />
        <Route path="/cartoons" element={<Cartoons / >} /> */}

        <Route path="/login" element={<LoginAll onLoginSuccess={handleLoginSuccess}  />} />
        <Route path="/liuren" element={isLoggedIn ? <Liuren /> : <Navigate to="/login" />} />
        <Route path="/" element={<IniP />} />
        <Route path="/overthewall" element={isLoggedIn ? <Overwall /> :  <Navigate to="/login" /> } />
        <Route path="/tools" element={isLoggedIn ? <Tools extendLogin={extendLogin} /> : <Navigate to="/login" />} />
        <Route path="/player" element={isLoggedIn ? <Music  extendLogin={extendLogin}/> : <Navigate to="/login" />} />
        <Route path="/translate" element={isLoggedIn ? <Translation extendLogin={extendLogin}/> : <Navigate to="/login" />} />
        <Route path="/nutrition" element={isLoggedIn ? <Nutrition extendLogin={extendLogin}/> : <Navigate to="/login" />} />
        <Route path="/cartoons" element={isLoggedIn ? <Cartoons extendLogin={extendLogin}/> : <Navigate to="/login" />} />
      
      </Routes>
    </Router>
    </ErrorBoundary>
  );
}
export default App;