
// 解密函数
function decrypt(data, key) {
    const CryptoJS = require('crypto-js');
    // 使用Base64解码数据
    const base64Data = CryptoJS.enc.Base64.parse(data);
    // 使用ECB模式和给定的密钥解密数据
    const decryptedData = CryptoJS.AES.decrypt(
        { ciphertext: base64Data },
        CryptoJS.enc.Utf8.parse(key),
        { mode: CryptoJS.mode.ECB }
    );
    // 将解密后的数据转换为UTF-8字符串
    return decryptedData.toString(CryptoJS.enc.Utf8).split('?')[0];
}
export default decrypt;