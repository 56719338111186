import React, { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import config from './config';
import resportEvents from './reportEvents';
function Translation({extendLogin}) {
    const [query, setQuery] = useState('');
    const [translation, setTranslation] = useState('');
    const [isTranslating, setIsTranslating] = useState(false); // 新增状态来指示是否正在翻译
    const [language, setLanguage] = useState('en'); // 新增语言状态
    const navigate = useNavigate();
    const { translate } = config;
    const handleBack = () => {
        resportEvents('back')
      navigate(-1); // 后退到上一个历史记录
    };

    const handleSubmit = async (event) => {
        
        extendLogin();
        event.preventDefault();
        if (!query) {
            alert('Pls input words to translate');
            return;
        }
        resportEvents(`do_translate${query}`)
        setIsTranslating(true); // 设置状态为正在翻译
        try {
            const response = await fetch(translate, {
            // const response = await fetch('https://127.0.0.1:5000/translate', {
                method: 'POST',
                body: JSON.stringify({ query: query , language:language}),
                headers: {
                    'Accept-Encoding': 'gzip',
                    'Content-Type': 'application/json'
                }
            });
            const data = await response.json();
            console.log(data['from']['language'])
            setTranslation(data['to']['text']); // 更新翻译结果
        } catch (error) {
            // console.error('Error:', error);
            resportEvents(`Error in translation: ${error.toString()}`)
            alert('咨詢超時 T_T 系唔系斷網啦～');
        } finally
        {
            setIsTranslating(false); // 请求结束后设置状态为不再翻译
        }
    }

    const handleChange = (event) => {
        setQuery(event.target.value); // 更新输入框的值
    }

    const handleLanguageChange = (event) => {
        console.log(event.target.value);
        setLanguage(event.target.value); // 更新选中的语言
        resportEvents('changeTo_'+event.target.value);
    }

    const handleClear = () => {
        setQuery(''); // 清空输入
        setTranslation(''); // 清空输出
    };

    useEffect(() => {
        console.log('Translation component rendered');
        // const session = window.sessionStorage.getItem('JSESSIONID');
        resportEvents('translation');
      }, []);

    return (
        <div className='Translation'>
            {/* <Link to="/" className="back-button">
                <p className="back">←</p>
            </Link> */}
            <button className='back-button' onClick={handleBack}>←</button>
            <div className="input-container">
                    <textarea name="query" placeholder="Input text to translate" value={query} onChange={handleChange} maxLength="1000" />
                    <form onSubmit={handleSubmit}>
                    <div className="info-bar">
                    <span>{query.length}/1000</span>
                        <button type="submit" disabled={isTranslating}>
                        {isTranslating ? 'loading...' : 'translate'} 
                        </button>
                        <select id="languageSelect" onChange={handleLanguageChange} defaultValue={language}> {/* 新增下拉框 */}
                            <option value="en">English</option>
                            <option value="zh-CN">中文</option>
                            <option value="ja">日本語</option>
                        </select>
                        <button type="button" onClick={handleClear}>clear</button> {/* 新增清空按钮 */}
                    </div>
                </form>
            </div>

            <div className="output-container">
                <textarea readOnly value={translation} />
            </div>
            <p className="footer">Powered by Google Translate</p> {/* 新增提示 */}
        </div>
    );
}

export default Translation;
