import bagua from './imges/bagua.jpg';
import arrow from './imges/arrow.png'
import { Outlet,Link, BrowserRouter as Router, Routes, Route, useLocation,useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react'

function ClickableBox({ position, numbers, url }) {
    const positions = [
      'top-left', 'top-center', 'top-right',
      'middle-right', 'bottom-right', 'bottom-center',
      'bottom-left', 'middle-left'
    ];
  
    const [opacity, setOpacity] = useState(0);
  
    useEffect(() => {
      const showBoxWithDelay = (index, delay) => {
        setTimeout(() => {
          setOpacity(1); // Set opacity to 1 after the delay
        }, delay);
      };
  
      showBoxWithDelay(position + 1, (position + 1) * 100); // Adjust delay based on position
  
      // Cleanup function to reset opacity when component unmounts
      return () => {
        setOpacity(0);
        // 重置为初始设置
      };
    }, [position]);
  
    return (
      <Link to={url} style={{ textDecoration: 'none' }}>
        <div className={`clickable-box ${positions[position]}`} style={{ opacity }}>
          <div className="box-content">
            <p>{numbers}</p>
          </div>
  
        </div>
      </Link>
    );
  }
  
  // 大首页
  function IniP() {
    const [showBoxes, setShowBoxes] = useState([false, false, false, false, false, false, false, false]);
    const numbers = ['📖', '💿', "🧰", "🥬", "◕‿◕", "☯", "🚷", "🚀"];
    const url = ["translate", "player", "tools", "nutrition", "cartoons", "liuren", "", "overthewall"]
    const [rotationSpeed, setRotationSpeed] = useState(2); // 初始旋转速度为2秒
    const location = useLocation();
    const background = location.state && location.state.background;
  
    useEffect(() => {
      const showBoxWithDelay = (index, delay) => {
        setTimeout(() => {
          setShowBoxes((prevShowBoxes) => {
            const newShowBoxes = [...prevShowBoxes];
            newShowBoxes[index] = true;
            return newShowBoxes;
          });
          // // 添加类名以触发动画
          // const clickableBoxElement = document.querySelector(`.clickable-box:nth-child(${index + 1})`);
          // if (clickableBoxElement) {
          //   clickableBoxElement.classList.add('animate');
          // }
        }, delay);
      };
  
      // Add class to each box with a delay to create a clockwise animation effect
      showBoxWithDelay(0, 0);
      showBoxWithDelay(1, 250);
      showBoxWithDelay(2, 500);
      showBoxWithDelay(3, 750);
      showBoxWithDelay(4, 1000);
      showBoxWithDelay(5, 1250);
      showBoxWithDelay(6, 1500);
      showBoxWithDelay(7, 1750);
  
      //middle image
      const animationTimeout = setTimeout(() => {
        const iniAnimationElement = document.querySelector('.ini-animation');
        // 等500ms后咨询
        if (iniAnimationElement) {
          iniAnimationElement.classList.add('active');
        }
      }, 500); // Delay in milliseconds before applying the animation
  
      return () => clearTimeout(animationTimeout);
    }, []);
  
  
    return (
      <div className='IniP'>
        <div className="ini-animation"  >
          <img
            src={bagua}
            alt="Animated_Image"
            className="ini-image"
          />
        </div>
        <div className="clickable-boxes">
          {showBoxes.map((isShown, index) => (
            isShown ? <ClickableBox key={index} position={index} numbers={numbers[index]} url={url[index]} /> : null
          ))}
        </div>
  
      </div>
    );
  }
  
  export default IniP;