import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import BouncingText from './BouncingText';
import resportEvents from './reportEvents';
function Cartoons({extendLogin}) {
    const [animeList, setAnimeList] = useState([]);
    const [page, setPage] = useState(1); // Add a new state variable for the current page
    const [year, setYear] = useState(new Date().getFullYear()); // Add a new state variable for the current year
    const [loading, setLoading] = useState(true); // Add a new state variable for loading status
    const [errorBack, setErrorBack] = useState(false); // Add a new state variable for loading status
    const [inputPage, setInputPage] = useState(''); // Add a new state variable for the input page
    const [previousPage, setPreviousPage] = useState('1')
    const [maxPage,setMaxPage] = useState(false)
    const [previousYear, setPreviousYear] = useState(new Date().getFullYear())
    const [isFirstRender, setIsFirstRender] = useState(true); // Add a new state variable for the first render
    const statusMapping = {
        'NOT_YET_RELEASED': '未发布期待捏~',
        'RELEASING': '连载中...',
        'FINISHED': '完结撒花～',
        'CANCELLED':'被腰斩了呜呜呜呜'
        // 添加其他状态映射
    };
    const navigate = useNavigate();
    const handleBack = () => {
        resportEvents('back')
      navigate(-1); // 后退到上一个历史记录
    };
    // 切换页数更新index
    useEffect(() => {
        setInputPage(page);
        if(page!==previousPage){
            console.log('page'+page)
            console.log('prepage'+previousPage)
            fetchAniListData(page, year);
            setLoading(true);
        }
        resportEvents("goTopage:"+page)
        window.scrollTo(0, 0); 
    }, [page]);
    // 切换年份后重置所有状态
    useEffect(()=>{
        setPage(1)
        setInputPage(1)
        setPreviousPage(1)
        if(year!==previousYear ){
            console.log('year'+year)
            console.log('preyear'+previousYear)
            fetchAniListData(1, year);
            setLoading(true);
        }
        resportEvents("goToyear:"+year)
        window.scrollTo(0, 0); 
    },[year]);

    const fetchAniListData = async (page) => { 
        extendLogin();
        try {
            const response = await fetch('https://graphql.anilist.co', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    query: `
                        query {
                            Page(page: ${page}, perPage: 10) {
                                pageInfo {
                                    total
                                    perPage
                                  }
                                media(seasonYear:${year}, season: WINTER, type: ANIME ,sort:UPDATED_AT_DESC,isAdult:false) {
                                    id
                                    title {
                                        native
                                    }
                                    episodes
                                    status
                                    startDate {
                                        year
                                        month
                                        day
                                    }
                                    coverImage {
                                        large
                                    }
                                    nextAiringEpisode { 
                                        episode
                                        airingAt
                                    }
                                    
                                }
                            }
                        }
                    `,
                }),
            });
            const data = await response.json();
            if (data.data.Page.media.length === 0 && !errorBack) { 
                alert('已达到最大页面'); // 添加这行
                setInputPage(previousPage); // 添加这行
                setPage(previousPage)
                setMaxPage(true)
                
            } else {
                setAnimeList(data.data.Page.media);
                setErrorBack(false)
                setMaxPage(false)
                setPreviousYear(year)
            }

        } catch (error) {
            console.error('Error fetching AniList data:', error);
            resportEvents(`Error: ${error.toString()}`)
            setErrorBack(true)
        }finally {
            setLoading(false); // Set loading to false after data fetching is complete
        }
    };
    // fetchAniListData(page, year);
    const handlePageInput = (e) => {
        const val = e.target.value;
        setInputPage(val);

    };
    const goToPage = () => {
        if(!maxPage){setPreviousPage(page)}
        const numPage = Number(inputPage);
        if (!isNaN(numPage) && numPage > 0) {
            setPage(numPage); // Only navigate to the page if input is a positive number
        }
        
        setInputPage(page); // Reset input to current page after navigation
    };

    const setBeforePage = () =>{
        if(!maxPage){setPreviousPage(page)}
        setPage(page -1 )
    };

    const setNextPage =()  =>{
        if(!maxPage){setPreviousPage(page)}
        setPage(page + 1)
    };
    useEffect(() => {
        // const session = window.sessionStorage.getItem('JSESSIONID');
        resportEvents('Cartoons');
      }, []);
    return (
        <div className="cartoons">
            {/* <Link to="/" className="back-button">
                <p className="back">←</p>
            </Link> */}
            <button className='back-button' onClick={handleBack}>←</button>


            <h1>{year} Anime Season</h1> {/* Add a heading to display the current year */}
            <select value={year} onChange={(e) => (setYear(e.target.value))}>
                {[...Array(10).keys()].map((_, i) => { // Generate options for the past 10 years
                    const yearOption = new Date().getFullYear() - i;
                    return <option key={yearOption} value={yearOption}>{yearOption}</option>
                })}
            </select>
            {errorBack ? <p className='bouncing-text'>网络问题😭稍后刷新重试叭～</p> :
            loading ? <BouncingText text="Loading..." />  : (
            <ul style={{ listStyleType: 'none' }}>
                {animeList.map((anime) => (
                                        <li key={anime.id} style={{ 
                                            border: '1px solid #ccc', 
                                            margin: '10px 0', 
                                            padding: '10px', 
                                            backgroundColor: '#da7f46' ,
                                            borderRadius: '10px' // 设置边框的钝角半径
                                        }}>
                        <img src={anime.coverImage.large} alt={anime.title.native} />
                        <h2>{anime.title.native}</h2>
                        <p>总集数: {anime.episodes ? anime.episodes : '未知'}</p>
                        <p>连载状态: {statusMapping[anime.status]}</p>
                        <p>发布时间: {anime.startDate.year}{anime.startDate.month ? -anime.startDate.month : ''}{anime.startDate.day ? -anime.startDate.day : ''}</p>
                        {anime.nextAiringEpisode ? ( // Use a conditional to show the next episode info
                        <p>
                            {anime.nextAiringEpisode.episode - 1 === 0
                                ? ''
                                : `最新发布: ${anime.nextAiringEpisode.episode - 1}`
                            }
                            </p> ) : ''}
                    </li>
                ))}
            </ul>
            )}
            <div className="pagination">
                {!loading && page > 1 && <button className='lastButton' onClick={setBeforePage}>Previous</button>}
                <button onClick={goToPage}>to</button>
                <input type="text" value={inputPage} onChange={handlePageInput} />
                {!loading && <button onClick={setNextPage}>Next</button>}
            </div>
        </div>
    );
}

export default Cartoons;