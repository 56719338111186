// import { Link } from "react-router-dom";
import React, { useState,useEffect } from 'react';
// import pako from 'pako';
import { useNavigate } from "react-router-dom";
import config from "./config";
import resportEvents from './reportEvents';

// function Tools({extendLogin}){
function Tools(){
    const [tab, setTab] = useState(1);
    const [url, setUrl] = useState('');
    const [fileName, setFilename] = useState('');
    const [isLoading, setIsLoading] = useState(false);  // 添加一个新的中间态
    const [isFileSelected, setIsFileSelected] = useState(false);  // 添加一个新的已选文件态
    const [isConverted, setIsConverted] = useState(false);  // 追踪转换完成状态
    const navigate = useNavigate();
    const {convert} = config
    const handleBack = () => {
        resportEvents('back')
      navigate(-1); // 后退到上一个历史记录
    };
    // Event handler for form submission
    const handleSubmit = async (event) => {
        // extendLogin();
        resportEvents('convert');
        event.preventDefault();
        setIsLoading(true)
        const formData = new FormData();
        const file = event.target.fileToConvert.files[0];
        if (!file) {
            alert('Pls input a file at first~');
            setIsLoading(false)
            return;
        }
        setIsFileSelected(true);  // 设置文件选择状态为 true
        formData.append('file', file);
        try {
            // const response = await fetch('http://localhost:9818/convert', {
            const response = await fetch(convert, {
            // const response = await fetch('http://localhost:5001/convert', {
                method: 'POST',
                body: formData,
            });
            // 获取文件名
            const contentDisposition = response.headers.get('Content-Disposition');
            let filename = '';
            if (contentDisposition) {
                const filenameMatch = contentDisposition.match(/filename=(.+)/);
                // if (filenameMatch.length === 2)
                // filename = filenameMatch[1];

                // const filenameMatch = contentDisposition.match(/filename\*=UTF-8''(.+)/);
                // if (filenameMatch.length === 2)
                // filename = filenameMatch[1];
                if (filenameMatch && filenameMatch.length === 2) {
                    filename = decodeURIComponent(filenameMatch[1]);
                }
            }

            // // 查看header兼职对
            // for (const [name, value] of response.headers.entries()) {
            //     console.log(`${name}: ${value}`);
            // }

            // // 解压接收
            // let data;
            // if (response.headers.get('Content-Encoding') === 'gzip') {
            //     // 如果响应被gzip压缩，需要进行解压缩
            //     console.log("111111")
            //     const compressedData = await response.arrayBuffer();
            //     // 哈希值查看
            //     const hash = await calculateHash(compressedData);
            //     console.log(`Hash: ${hash}`);
            //     // 数据解压
            //     const inflatedData = pako.inflate(compressedData);
            //     console.log("333333333");
            //     data = new Blob([inflatedData], { type: 'audio/mp3' });
            //     console.log("444444444");
            // } else {
            //     data = await response.blob();
            //     console.log("22222")
            // }

            // 不解压接收
            const data = await response.blob();  // 使用 blob() 处理响应

            const url = URL.createObjectURL(data);  // 创建一个 URL

            
            // // 哈希值计算
            // async function calculateHash(arrayBuffer) {
            //     const hashBuffer = await crypto.subtle.digest('SHA-256', arrayBuffer);
            //     const hashArray = Array.from(new Uint8Array(hashBuffer));  // 将 buffer 转换为 byte 数组
            //     const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');  // 将 bytes 转换为十六进制字符串
            //     return hashHex;
            // }
            // const compressedData = await response.arrayBuffer();
            // const hash = await calculateHash(compressedData);
            // console.log(`Hash: ${hash}`);

        setUrl(url);
        setFilename(filename)
        setIsConverted(true);  // 设置转换完成状态为 true
        // // 法二：创建一个隐藏的 <a> 元素，然后模拟用户点击它
        // const link = document.createElement('a');
        // link.href = url;
        // link.download = filename;
        // link.style.display = 'none';
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
        } catch (error) {
            alert('service GG🐶')
            // console.error('Error:', error);
            resportEvents(`Error in convertor: ${error.toString()}`)
        } finally{
            setIsLoading(false)
        }
    };

    const handleTabChange = (tabIndex) => {
        setTab(tabIndex);
        resportEvents('tabindex-'+tabIndex); // Report event when tab changes
    };

    useEffect(() => {
        // const session = window.sessionStorage.getItem('JSESSIONID');
        resportEvents('/Tools');
      }, []);

    return (
     <div>
        <header>
        <nav className='tab-nav'>
        <button className={`toolTab ${tab === 1 ? 'selected' : ''}`}  onClick={() => handleTabChange(1)}>{'{'}ncm,flac{'}'} to mp3</button>
        <button className={`toolTab ${tab === 2 ? 'selected' : ''}`}  onClick={() => handleTabChange(2)}>其他工具</button>
      </nav>
        </header>
      <div className='tools'>
        {/* <Link to="/" className="back-button">
          <p className="back">←</p>
        </Link> */}
        <button className='back-button' onClick={handleBack}>←</button>
        {tab === 2 && ( <table border="2">
          <thead>
              <tr>
                  <th>Tools</th>
                  <th>URL</th>
              </tr>
          </thead>
          <tbody>
              <tr>
                  <td>Music-convertor</td>
                  <td><a href="https://www.zhuanhuanyun.cn/?to=.zh-warp" target='blank'>ncm-{'>'}flac</a></td>
              </tr>
              <tr>
                  <td>Music-convertor</td>
                  <td><a href="https://audio.worthsee.com/convert/FLAC-to-MP3" target='blank'>Flac-{'>'}mp3</a></td>
              </tr>
              <tr>
                  <td>Music-convertor</td>
                  <td><a href="https://www.zhuanhuanyun.cn/?to=.zh-warp" target='blank'>Common-{'>'}mp3</a></td>
              </tr>
              <tr>
                <td>身强|身弱查询</td>
                <td><a href='https://m.smxs.com/bazi/sqsr.html' target='blank'>身强|身弱查询</a></td>
              </tr>
            </tbody>
            </table>)}
            {tab === 1&&(<div>
                <form id="uploadForm" encType="multipart/form-data" onSubmit={handleSubmit}>
                <input type="file" name="fileToConvert" id="fileToConvert" accept=".ncm,.flac"
                        disabled = {isLoading}
                        onChange={event => {
                            
                            const file = event.target.files[0];
                            if (file) {
                                resportEvents('SelectFile:'+file.name);
                                if (file.size > 50 * 1024 * 1024) {  // 10MB
                                    alert('文件太大呢啦，不能超过 50MB');
                                    event.target.value = '';  // 清除选定的文件
                                }
                                setIsLoading(false);  // 重置转换状态
                                setIsConverted(false);  // 重置转换完成状态
                            }else{

                                setIsFileSelected(false);  // 如果没有选择文件，设置文件选择状态为 false
                                setIsConverted(false) // 如果没有选择文件，设置已转状态为 false
                            }
                        }}
                    />
                <button type="submit" disabled={isLoading || isConverted}>
                    {isLoading ? '转换中...' : isConverted ? '已完成' : '转换'}
                </button>
                </form>
                {!isLoading && isFileSelected && isConverted && 
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <a href={url} download={fileName}>download file</a>
                </div>}
            </div>)}
      </div>
      </div>   
    );
  }

  export default Tools;