import config from './config';
async function resportEvents(entry,currentTime = new Date(),songName = ''){
    const {report} = config
    // 调用方可以选择是否提供当前时间，如果不提供，则默认为当前时间。
    try {
        // 获取缓存中的用户名
        const username = localStorage.getItem('username');
        // 检查用户名是否存在
        // if (username) {
        //     // 如果用户名存在，则执行 fetch 请求
        //     await fetch('http://localhost:5000/report',{
        //         method: 'POST',
        //         headers:{
        //             'Content-Type':'application/json'
        //         },
        //         body:JSON.stringify({
        //             username: username,
        //             entry: entry,
        //             currentTime: currentTime.toISOString(), // 将传入的时间对象转换为 ISO 格式的字符串
        //             songName:songName
        //         }),
        //         credentials: 'include'  //告诉浏览器在跨域请求中包含Cookie
        //     });
        // }

        if (username) {
            // 如果用户名存在，则执行 fetch 请求
            const requestBody = {
                username: username,
                entry: entry,
                currentTime: currentTime.toISOString() // 将传入的时间对象转换为 ISO 格式的字符串
            };

            requestBody.userAgent = navigator.userAgent || 'Unknown'; // 获取浏览器用户代理信息
            requestBody.platform = navigator.platform || 'Unknown'; // 获取设备平台信息，如果 platform 未定义，则默认为 'Unknown'
            requestBody.language = navigator.language || 'Unknown'; // 获取设备语言信息，如果 language 未定义，则默认为 'Unknown'
            // 如果传入了 songName 参数，则将其添加到请求体中
            if (songName) {
                requestBody.songName = songName;
            }
            // await fetch('http://localhost:12345/report', {
            await fetch(report, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody),
                credentials: 'include' // 告诉浏览器在跨域请求中包含Cookie
            });
        } else {
            console.log('没有找到用户名，无法发送报告。');
            
        }
    } catch (error) {
        console.error('服务挂了淦:', error);
       
        
    }
}
export default resportEvents;