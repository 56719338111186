
import resportEvents from './reportEvents';
import bagua from './imges/bagua.jpg';
import arrow from './imges/arrow.png'
import { Outlet,Link, BrowserRouter as Router, Routes, Route, useLocation,useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react'
import lunarCalendar from 'lunar-calendar';
function padZero(number) {
    return (number < 10 ? '0' : '') + number;
  }
  let animationTimeout;
function Liuren() {
    // const [selectedMonth, setSelectedMonth] = useState('');
    // const [selectedDay, setSelectedDay] = useState('');
    // const [selectedTime, setSelectedTime] = useState('');
    const [final, setfinal] = useState('');
    const liushen = ["大安", "留连", "速喜", "赤口", "小吉", "空亡"]
    const [showAnimation, setShowAnimation] = useState(false);
    const navigate = useNavigate();
    const handleBack = () => {
      resportEvents('back')
      navigate(-1); // 后退到上一个历史记录
    };
    // 新增函数用于更新农历时间
    const updateLunarTime = () => {
      const currentDate = new Date();
      const lunarDate = lunarCalendar.solarToLunar(currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate());
      const formattedLunarDate = `${lunarDate.lunarYear}年${lunarDate.isleap ? '闰' : ''}${lunarDate.lunarMonthName}${lunarDate.lunarDayName}`;
      // 更新显示农历时间的元素内容
      const hours = currentDate.getHours();
      const minutes = currentDate.getMinutes();
      const seconds = currentDate.getSeconds();
      const formattedTime = padZero(hours) + ":" + padZero(minutes) + ":" + padZero(seconds);
  
      document.getElementById('lunarTime').innerText = `农历 ${formattedLunarDate} ${formattedTime}`;
    };
      
  
    useEffect(() => {
      resportEvents('liuren');
      const lunarMonths = ['正月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '冬月', '腊月'];
      const lunarMonthSelect = document.getElementById('lunarMonth');
      lunarMonthSelect.innerHTML = '';
      lunarMonths.forEach((month, index) => {
        const option = document.createElement('option');
        option.value = index + 1; // 月份从1开始【默认值为0】
        option.text = month;
        lunarMonthSelect.appendChild(option);
      });
  
      const lunarDays = ["初一", "初二", "初三", "初四", "初五", "初六", "初七", "初八", "初九", "初十", "十一", "十二", "十三", "十四", "十五", "十六", "十七", "十八", "十九", "二十", "廿一", "廿二", "廿三", "廿四", "廿五", "廿六", "廿七", "廿八", "廿九", "三十"];
      const lunarDaysSelect = document.getElementById('lunarDay');
      lunarDaysSelect.innerHTML = '';
      lunarDays.forEach((day, index) => {
        const option = document.createElement('option');
        option.value = index + 1; // 月份从1开始【默认值为0】
        option.text = day;
        lunarDaysSelect.appendChild(option);
      });
  
      const timeOptions = ["子时（23:00-01:00）", "丑时（01:00-03:00）", "寅时（03:00-05:00）", "卯时（05:00-07:00）", "辰时（07:00-09:00）", "巳时（09:00-11:00）", "午时（11:00-13:00）", "未时（13:00-15:00）", "申时（15:00-17:00）", "酉时（17:00-19:00）", "戌时（19:00-21:00）", "亥时（21:00-23:00）"];
      const timeSelect = document.getElementById('Time');
      timeSelect.innerHTML = '';
      timeOptions.forEach((time, index) => {
        const option = document.createElement('option');
        option.value = index + 1; // 月份从1开始【默认值为0】
        option.text = time;
        timeSelect.appendChild(option);
      });
      setfinal('');
  
      // 初始更新农历时间
      updateLunarTime();
  
      // 设置定时器，每隔一定时间更新一次农历时间
      const intervalId = setInterval(updateLunarTime, 1000); // 每秒更新一次，可以根据需要调整间隔时间
  
      // 在组件卸载时清除定时器
      return () => clearInterval(intervalId);
  
    }, []); // 空的依赖数组确保 useEffect 只在组件挂载时运行一次
  
    const handleCalculate = () => {
      resportEvents('推算')
      const num_Month = parseInt(document.getElementById('lunarMonth').value, 10);
      const num_Day = parseInt(document.getElementById('lunarDay').value, 10);
      const num_Time = parseInt(document.getElementById('Time').value, 10);
      // setSelectedMonth(num_Month);
      // setSelectedDay(num_Day);
      // setSelectedTime(num_Time);
      const final_value = (num_Day + num_Month + num_Time - 2) % 6 - 1;
      const adjusted_final_value = final_value === -1 ? 5 : final_value;
      setfinal((liushen[adjusted_final_value]) + "!")
    
      setShowAnimation(true);
      // 设置一个定时器，在动画完成后重置状态
  
      animationTimeout = setTimeout(() => {
      }, 3000); // 这里的时间应该与动画的持续时间相匹配
  
      const animationTextElement = document.getElementById('animation-text');
      if (animationTextElement) {
        animationTextElement.scrollIntoView({ behavior: 'smooth' });
      }
    };
    // document.getElementById('final').style.display = "block";
    const handleOptionChange = () => {
      setfinal('');
      clearTimeout(animationTimeout);
      setShowAnimation(false);
    };
    return (
      <div className="App">
        {/* <Link to="/" className='back-button' >
          <img src={arrow} className="App-logo3" alt="<" />
        </Link> */}
  <button className='back-button' onClick={handleBack}>←</button>
        <p className='sub_title'>小六壬</p>
        <img src={bagua} className="App-logo2" alt="J～" />
        <div>
          <label htmlFor="lunarMonth">农历月份   </label>
          <select id="lunarMonth" onChange={handleOptionChange}></select>
          <br></br>
          <label htmlFor="lunarDay" >农历日期   </label>
          <select id="lunarDay" onChange={handleOptionChange}></select>
          <br></br>
          <label htmlFor="Time">时辰   </label>
          <select id="Time" onChange={handleOptionChange}></select>
          <br></br>
  
          <button onClick={handleCalculate}>推算</button>
          {showAnimation && (
            <div id="animation-text" className="animation-text">
              {final}
            </div>
          )}
        </div>
  
        <footer className="footer">
          {/* 将实时农历时间显示在底部 */}
          <p id="lunarTime" className='lunar-time'></p>
        </footer>
      </div>
    );
  }
  export default Liuren;