import React from 'react';

const BouncingText = ({ text }) => {
  return (
    <div className="bouncing-text">
      {text.split('').map((char, index) => (
        <span key={index} className="bouncing-letter" style={{ animationDelay: `${index * 0.1}s` }}>{char}</span>
      ))}
    </div>
  );
};

export default BouncingText;