import React, { useState, useRef, useEffect, useContext } from 'react'; // 引入 useRef
import decrypt from './decrypt';
import { useNavigate } from "react-router-dom";
import resportEvents from './reportEvents';
import config from './config';
function Music({extendLogin}) {
  // const [songs, setSongs] = useState([]); // 保存音乐链接的状态
  // const [songUrls, setSongUrls] = useState([]);
  const [index, setIndex] = useState(null);
  const [songNames, setSongNames] = useState([]); // 保存歌曲名称的状态
  const [volume, setVolume] = useState(0.3); // 默认音量为 0.5
  // 在组件中创建一个状态变量 index，并提供了一个名为 setIndex 的函数，用于在组件中更新 index 的值。
  const audioRef = useRef(); // 创建一个 ref
  const [showBackToTop, setShowBackToTop] = useState(false); // 控制回到顶部按钮显示状态
  // const CryptoJS = require('crypto-js');
  const customKey = "xiaoshenshuaideyibiaaaaa";
  const navigate = useNavigate();
  const {MusicUrlGet} = config
  const [playMode, setPlayMode] = useState('➡'); // 默认为顺序播放
  const [showJumpToNow, setShowJumpToNow] = useState(false); // 控制"Jump to Now"按钮的显示状态



  const handleBack = () => {
    resportEvents('back')
    navigate(-1); // 后退到上一个历史记录
    // bug修复：数据返回延时退出页面仍播放音乐
    const audio = audioRef.current;
    if (audio) {
      audio.pause();
      audio.currentTime = 0; // 将播放时间重置为0
    }
  };

  const playSong = async (newIndex) => {
    // console.log("playSongIndex:"+newIndex)
    // console.log( songNames[newIndex][0])
    // console.log("+++++++++")
    // console.log(newIndex)
    // console.log(songNames[newIndex])
    // console.log("+++++++++")
    resportEvents('Select Song:'+songNames[newIndex][0])
    setIndex(newIndex);
    const audio = audioRef.current;
    if(audio){
    audio.src = await fetchSongUrl(songNames[newIndex][0]); // bug：未能顺利切换音乐-需更新音乐链接
    // console.log("+++++++++")
    // console.log(songNames[newIndex][0])
    // console.log("+++++++++")
    // console.log(audio.src)
    // console.log("+++++++++")
    audio.oncanplaythrough = () => {
      // console.log("playSongIndex2:"+newIndex)
      audio.play();
    };
    audio.load();}
    extendLogin();
  };

  // const changeSong = (chooseIndex) => {
  //   let newIndex = typeof chooseIndex === 'number' ? chooseIndex : index; // Default to current index if chooseIndex is not provided
  //   playSong(newIndex);
  // };

  const changeSong = async (chooseIndex) => {
    const audio = audioRef.current;
    if (audio) {
      audio.pause();
    }
    let newIndex = typeof chooseIndex === 'number' ? chooseIndex : index; // Default to current index if chooseIndex is not provided
    // const songName = songNames[newIndex];
    // const songUrl = await fetchSongUrl(songName[0]);
    // setSongUrls((prevUrls) => {
    //   const newUrls = [...prevUrls];
    //   newUrls[newIndex] = songUrl;
    //   return newUrls;
    // });
    playSong(newIndex);
  };

  const togglePlayMode = () => {
    if (playMode === '➡') {
      setPlayMode('🔀');
    } else if (playMode === '🔀') {
      setPlayMode('♳');
    } else {
      setPlayMode('➡');
    }
  };

  // // 仅顺序播放
  // const afterSong = () => {
  //   const audio = audioRef.current;
  //   if (audio) {
  //     audio.pause();
  //   }
  //   let newIndex = (index + 1) % songNames.length;
  //   // console.log('--------------')
  //   // console.log(index)
  //   // console.log(newIndex)
  //   // console.log('--------------')
  //   // console.log("afterSong:"+newIndex)
  //   playSong(newIndex);
  // };

  const afterSong = () => {
    const audio = audioRef.current;
    if (audio) {
      audio.pause();
    }
    if (playMode === '➡') {
      let newIndex = (index + 1) % songNames.length;
      playSong(newIndex);
    }else if(playMode === '🔀'){
        let randomIndex = Math.floor(Math.random() * songNames.length);
        playSong(randomIndex);
    }else{
      playSong(index);
      // audio.onended = playSong(index)
      // // 直接赋值：
      // // 1.切换后马上执行：这是赋值行为，在useEffect运行时被调用，而不是在音频播放结束时
      // // 2.js报错 ：无初始值
      // // 3.播放完歌曲不执行：因为onended被赋值为playSong(index)的返回值而不是函数本身
    }
  };

  

// // 仅顺序播放
//   const beforeSong = () => {
//     const audio = audioRef.current;
//     if (audio) {
//       audio.pause();
//     }
//     let newIndex = (index - 1) % songNames.length;
//     if (newIndex < 0) {
//       newIndex = songNames.length - 1; // Loop to the last song if index becomes negative
//     }
//     playSong(newIndex);
//   };

  const beforeSong = () => {
    const audio = audioRef.current;
    if (audio) {
      audio.pause();
    }
    if (playMode === '➡') {
      let newIndex = (index - 1) % songNames.length;
      if (newIndex < 0) {
        newIndex = songNames.length - 1; // Loop to the last song if index becomes negative
      }
      playSong(newIndex);
    }else if(playMode === '🔀'){
        let randomIndex = Math.floor(Math.random() * songNames.length);
        playSong(randomIndex);
    }else{
      playSong(index);
      // audio.onended = playSong(index)
      // // 直接赋值：
      // // 1.切换后马上执行：这是赋值行为，在useEffect运行时被调用，而不是在音频播放结束时
      // // 2.js报错 ：无初始值
      // // 3.播放完歌曲不执行：因为onended被赋值为playSong(index)的返回值而不是函数本身
    }


  };


  const handleVolumeChange = (event) => {
    const newVolume = parseFloat(event.target.value);
    // 当前音量设定
    setVolume(newVolume);
    const audio = audioRef.current;
    // 全局音量设定
    audio.volume = newVolume; // 拖动设置音量
  };

  // obtain url
  const fetchSongUrl = async (songName) => {
    // todo:文件名加密 
    const songIndex = btoa(unescape(encodeURIComponent(songName)));
    try {
      const response = await fetch(MusicUrlGet, {
      // const response = await fetch('http://127.0.0.1:5010/MusicUrlGet', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              songIndex: songIndex,
            }), 
        });;
      const data = await response.json();
      // console.log(decrypt(data['fileUrl'],customKey))
      return decrypt(data['fileUrl'],customKey)
    } catch (error) {
      resportEvents('back')
      resportEvents(`Error in fetching song: ${error.toString()}`)
    }
  };

  useEffect(() => {
    resportEvents('MusicPage')
    const checkAndUpdateCSVData = async () => {
      try {
        // 从服务器获取最新的 csvData
        const response = await fetch('/music_links/encrypted.csv');
        const newCSVData = await response.text();
    
        // 比较新旧 csvData 是否相同
        const oldCSVData = localStorage.getItem('csvData');
        if (newCSVData !== oldCSVData) {
          // 更新本地 localStorage
          localStorage.setItem('csvData', newCSVData);
          console.log('csvData 已更新');
        } else {
          console.log('csvData 未更新');
        }
      } catch (error) {
        console.error('Error checking and updating CSV data:', error);
      }
    };
    const interval = 60 * 60 * 1000; // 一小时的毫秒数
    setInterval(checkAndUpdateCSVData, interval);
    checkAndUpdateCSVData(); // 初始化时立即执行一次检查更新操作

    // resportEvents('Music');
    // 在组件挂载时提取音乐链接数据
    const fetchSongsFromCSV = async () => {
      try {
        let csvData;
        // 检查 localStorage 是否有数据
        if (localStorage.getItem('csvData')) {
          csvData = localStorage.getItem('csvData');
          console.log('csvData 使用缓存');
        } else {
          // 从服务器再次获取音乐列表
          const response = await fetch('/music_links/encrypted.csv');
          csvData = await response.text();
          // 将列表数据存储到 localStorage
          localStorage.setItem('csvData', csvData);
          console.log('csvData 重试更新');
        }
        const rows = csvData.split('\n').slice(1); // 跳过标题行
        // 从数组的第二个元素开始（索引为1），提取到数组的最后一个元素


        // // 本地缓存播放名称及地址
        // const songNames = [];
        // const songLinks = [];
        // rows.forEach(row => {
        //   const columns = row.split(','); // 使用逗号分割每一行
        //   const songName = columns[0].split('.mp3');
        //   songNames.push(songName); // 将歌曲名称添加到 songNames 数组中
        //   songLinks.push(columns[1]);
        //   // 非标准格式清洗【无解密】
        //   // columns.forEach(column => {
        //   //   if (column.startsWith('https')) { // 如果列的内容以 HTTPS 开头
        //   //     const urlBeforeQuestionMark = column.split('?')[0]; // 在 '?' 处分割字符串，并取第一部分
        //   //     songLinks.push(urlBeforeQuestionMark); // 将修改后的 URL 添加到 songLinks 数组中
        //   //   }
        //   // });
        // });
        // // file url decry
        // const decryptedLinks = songLinks.map(link => {
        //   let decrypted = '';
        //   try {
        //     decrypted = decrypt(link, customKey);
        //   } catch (error) {
        //     console.error('Error decrypting link: ', error);
        //   }
        //   return decrypted;
        // });
        // setSongs(decryptedLinks);
        // setSongNames(songNames);



        // 本地仅缓存播放名称-音乐名称list
        const songNames = [];
        rows.forEach((row, index) =>{
          if (index < rows.length - 1) {
            const songName = row.split('.mp3');
            songNames.push(songName);
          }
        });
        setSongNames(songNames)


      } catch (error) {
        // console.error('Error fetching songs:', error);
        resportEvents('Error:'+ error)
      }
    };

    // fetchSongsFromCSV().then(() => {
    //   // 歌曲链接已经被成功获取，现在可以播放音乐了
    //   const audio = audioRef.current;
    //   if(audio){
    //     audio.onended = afterSong; // 播放完切换到下一首
    //   }
    // });
    fetchSongsFromCSV();
    // 组件卸载时清除定时器等操作
    return () => {
      // 清理操作（当组件卸载时停止音乐的播放并将播放时间重置为0）
      const audio = audioRef.current;
      if (audio) {
        audio.pause();
        audio.currentTime = 0; // 将播放时间重置为0
      }
    };
  }, []); // 依赖数组为空，表示只在组件挂载时执行一次

  const handleBackToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      setShowBackToTop(scrollTop > 0); // Toggle the back to top button based on scroll position
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

    // 定义歌曲播放结束后的下一首播放模式
  useEffect(() => {
    const audio = audioRef.current;
    // console.log("endindex:"+index)
    if(audio){
      if (playMode === '➡') {
        audio.onended = afterSong
      }else if(playMode === '🔀'){
        audio.onended = () => {
          let randomIndex = Math.floor(Math.random() * songNames.length);
          playSong(randomIndex);}
      }else{
        audio.onended = () => playSong(index);
        // audio.onended = playSong(index)
        // // 直接赋值：
        // // 1.切换后马上执行：这是赋值行为，在useEffect运行时被调用，而不是在音频播放结束时
        // // 2.js报错 ：无初始值
        // // 3.播放完歌曲不执行：因为onended被赋值为playSong(index)的返回值而不是函数本身
      }
      // audio.onended = afterSong; // 播放完切换到下一首
    }
  }, [index,playMode]); // 当 index 或 playMode 变化时，更新处理函数

  useEffect(() => {
    setShowJumpToNow(index !== null); // 当开始播放音乐时，显示"Jump to Now"按钮
  }, [index]);

  const handleJumpToNow = () => {
    const songElement = document.getElementById(`song-${index}`);
    if (songElement) {
      // songElement.scrollIntoView({ behavior: 'smooth' });
      const elementPosition = songElement.getBoundingClientRect().top;
      // 获取元素相对于视口的顶部位置
      const offsetPosition = elementPosition - window.innerHeight / 2;
      // 视口高度的一半减去元素的顶部位置
      window.scrollBy({ 
        top: offsetPosition, 
        behavior: 'smooth' 
      });
    }
  };

  return (
    <div className='Music'>
      <button className='back-button-mu' onClick={handleBack}>←</button>
      <h2 className={index !== null ? "rainbow-animation" : "fade-in-out"} style={{ textAlign: 'center', marginTop: '10%' }}>{index !== null ? songNames[index] : "Please select a song to play"}</h2>
      <audio ref={audioRef} controls> {/* 使用 ref */}
        {/* {index !== null && <source src={songs[index]} type="audio/mpeg" />} */}
      </audio>
      <br />
      <div className="button-container">
        <button onClick={beforeSong}>Last</button>
                <button onClick={togglePlayMode}>{playMode}</button>
        <button onClick={afterSong}>Next</button>
      </div>
      <div className="volume-control">
        <button onClick={() => {
          const newVolume = Math.max(0, volume - 0.1);
          setVolume(newVolume);
          const audio = audioRef.current;
          audio.volume = newVolume;
        }}>V--</button>
        <input
          type="range"
          min="0"
          max="1"
          step="0.01"
          value={volume}
          onChange={handleVolumeChange}
        />
        <button onClick={() => {
          const newVolume = Math.min(1, volume + 0.1);
          setVolume(newVolume);
          const audio = audioRef.current;
          audio.volume = newVolume;
        }}>V++</button>
      </div>
      <div className="song-list">
        <h3>Song List</h3>
        <ul>
          {songNames.map((songName, idx) => (
            <li key={idx} id={`song-${idx}`} onClick={() => changeSong(idx)} style={idx === index ? { color: 'white' } : {}}>{songName}</li>
          ))}
        </ul>
      </div>
      {showJumpToNow && (
        <div className="jump-to-now-button" onClick={handleJumpToNow}>
          Jump to Now
        </div>
      )}
      {showBackToTop && (
        <div className="back-to-top-button" onClick={handleBackToTop}>
          Back to Top
        </div>
      )}
    </div>
  )
}

export default Music;